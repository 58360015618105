<template>
  <b-container>
    <b-table striped hover :items="items"></b-table>
  </b-container>
</template>

<script>
/* eslint-disable no-console */

import { mapState, mapMutations, mapActions } from 'vuex'
import { db } from '../plugins/firebase'

export default {
  components: {},
  computed: {
    ...mapState([
      'pid',
      'ppid',
      'doc',
      'editor']),
    ...mapState({
      pids: state => state.doc.problemPack.pids,
      problem: state => state.editor.problem,
      problemSolution: state => state.editor.problemSolution,
      explanation: state => state.editor.explanation.contents,
    }),
    pidOptions: function(){
      return this.pids.map((pid, idx)=>{ return { text: `${idx+1}`, value: pid } })
    },
    explanationModalAddState() {
      const name = this.explanationModalInput
      return name.length>0
        ? !Object.keys(this.explanation).includes(name)
        : null
    },
    explanationModalDeleteState() {
      const name = this.explanationModalInput
      return name.length>0
        ? Object.keys(this.explanation).includes(name)
        : null
    }
  },
  data() {
    return {
      uids: [
        // "Y1PUfiAYzhgAGfB7xxWp1cQCkxg1",
        "2QGaGVz7IvRwYItPEM0hWeO6uOQ2",
        "Ybj7MzI7PvSKU1Nu61Z1nAMkbDE3",
        "zB7TpdY578aFToER3A38PsotGzJ2",
        "jotrWKoQblcmPlcMsRO4orpTtoB3",
        "dy7hpi6mwfTw49nooGvv9JpItfx1",
        "nHMOrJbF28RFQiCw6PMY4noqu1k1",
        "sW9icbS7HjQOhy8BoylivIU5Ioj2",
        "y3HTP2BOk3ZW2GTuKbji0r0wa843",
        "cU4YrY322nZvHfxuJZ7KOZOVyyg1",
        "yt7YYvE5jkM0gonBhzAwTz6PYrm1",
        "z1qKCb5qQBTNdCYtk3S5mHN8QNx1",
        "i7zP0lYrR8NQTbtj0metRof4Lwr2",
        "G9i82d0tr2PWi7CsQU7WgQhBZcB3",
        "WT5p8SEjXKY2AhO41Dbmk815MdB2",
        "wvNQK3cZxiVOqRIhUkxC5iOTcHE3",
        "PidaU4bAy3hPlmG3L67yxfZLoet2",
        "y9dG8SzE9QO5PpdY4zaorvNmLLw1",
        "C4YtgEJcPWZMCiyC6lK92V23XOC3",
        "BnYwhenVXIhoFUKiwOyhgOtHM9B2",
        "Gaxg0ELQHASvSrIOKhg8rwZD2no1",
        "qrl2OpsyGgdEzgd6diuTgLE2YHB3",
        "e9TGFLleTQfQihbDrkn0RI4I8ih2",
        "DTjMBJXBBZWOx1hzvpAZndjejRr2",
        "8aEpXs8arZbjW3GHHr3SDRYgDUk1",
        "cl9h1SCHebMZsQcVHGrG70OUE7S2",
        "QunEpSS634XL25wSJxZvtYdmIYB2",
        "2VJsNTojvZSmZ1G6iWwzAUzIL8L2",
        "Siac2114SHcx24mF4wQxSmUnAoz1",
        "Mh1uwhf8lMZN7yyIt9bVTDsqeS62",
        "vxpkCmAMlEPbxj5xTlu645m9SVh1",
        "8p5WABVDskbAThSdBBYzlMQXOLP2",
        "XMcffDgj9IQDo2kdrBLGw2W5FJj1",
        "fzoFHi5a8tW3jMVGTvgC0ihhSij2",
        "H9PPQufomgchPvTmTPIptRohmbA2",
        "xXhZUqVke4SWtthEnTIeCUlqxri2",
        "G8oHIClxTaQoIw3AZQ5md1Ev0Tn2",
        "CnhJjfUdj7WyUEIaAVOOZqkRBw23",
        "F0QkbOaWVYXFx0nebT06B4kyqxv2",
        "TbSb0w6T0wT5hLSCbYg2bzBjASs1",
        "gv8DbsEK47NDqZKsvlolSfQpny53",
        "A9YbHQelNBaT8yDVpSNIFZMs2ZS2",
        "eS6eRKRlOFZvK02BXWEu7PKxROk2",
        "z0LKKx1WIpRyCl9gUiFfkpZEpt83",
        "RyFfl68zJsTA1w6e9SkqE8ZVXDt2",
        "q3TSbzi1enZWxMchNIcrF437aMf2",
        "LJKoNj3dk7XFhlt4DKXfY7HYuq32",
        "UcyJOpqXyjUCA1PycqsGCgq5vl12",
        "1zIY9j5NPPUFEPdxuS9LjezMsEG3",
        "zRSgGou8UpO6AtZlGhMKWcJS7DB3",
        "2eYr3dao64czNOdmXrsPuzk31Ir2",
        "YbNXABEuv2ThRfLjZmuIWVEcfc82",
        "BfrnhZzFrCTNmvohzfLMpExZQ9S2",
        "cOmotZZM35aOdr7yfHwhUAZ6eT92",
        "rHwmkhsXonOjQxriHfLv4quaeAJ2",
        "mWyXAKsZc7Wh1DTH8gGaeeOA8Y83",
        "Xq98ht3cRecpytqxAQvFFlgqmYt2",
        "YwsFgqathhhVNaStZ1fFdMfgYnV2",
        "tHkwlRby33TEk8dwFocMkxIU3hE3",
        "Ky851v8MqfSmpraDBOskfvXEqM02",
        "r1SgYl2IFUUI9OhnrXwgUu0rFPM2",
        "f3vka2pA4YYBxM2sUuqAoB6tMi42",
        "eP6S2KXfN0aNv4XxekpY4DhQIiI2",
        "F3e3KEjHGaU3YiL3YeVp9Ntk7kX2",
        "GxXzWyRiznWmCDvVDAik805ZuPw1",
        "5NeIPnNAhHY6mg1OY5Xsp2eDrWk1",
        "suCi5oaxFBRTIYvvzij8nItLDM52",
        "9sKsD3MlizbGvORr38v7HPLHNtl1",
        "I4HZXvGG7oQsnHs5jh1WZFvdHJl2",
        "qOXx0A0mNqSoJ9oLApyEiR5IiO32",
        "TRvLuTPWxrdbDLXo7X2qs2CgaWu2",
        "hEBjxZQrQuaNDAoyGkd17SybHMt2",
        "ArqvIMFvIKStsUqh2jQJRtQdohC2",
        "XfMGhkeBhiTi4dtf8Ql7rjcHYXT2",
        "nbXZnwhlBShLDc7X5BqWcnGySVh2",
        "udnKNdf4cnZQJBT8mDRqVoW2OG93",
        "ymrPq2rAh8XMvKwnEUha0ZTizIq1",
        "THrvDjSL4ChuRdOrxmXBD2hJlBf1",
        "ZitqqNLrq9SGaZUTQ50hzLBZYGg2",
        "JluYaWvr63ShdiONU1MWDVl7jNC2",
        "iZ6UvzyaDvUi0uh9OvnAYH4pvFo2",
        "07CC9nw88cMH27HfbdtwEqrHtix2",
        "k4lxxGZGiDWdNgCkR424Y3JvFJk2",
        "fNN68WhHDCgx2CCxnFsAvJHocXg1",
        "ZsCJf0Rx3TOF41upZB3wJlXROUG3",
        "CXvRLGMFWohlCT8GfLN4zM9wfsg2",
        "H2lOYVdoJzeNhDOdTsF7oqbQc0N2",
        "FZAxdWhgx1WWxjB2AD3LRTcSeq22",
        "tzxto5OgeCcT61bj7qYoRJKWKzo2",
        "nlbvb6nugdMHVMH4FO8BwlQymog1",
        "edyxvEnus0cY9xrbnFUVw01k1rj1",
        "OVRiGnS3CAcBjWyqJoxOgqC8lZj2",
        "hGoue1vXcKgbrWgdO9AssgNiwk83",
        "Xi5GLbhYHgWDGCFTle8FLnjgcrv2",
        "a83VDm5Xtwb9ElwjjUr8trc0niL2",
        "CviKM4anOzVn6dNh096hneTNaSX2",
        "AOSm4wXm1xcKl38H7jcoKo6uVLT2",
        "cc4JWwiUp9YKLUxBvEbghQWcT1j1",
        "LkQCCRrnIdcWU3pOuvpLEF7IpE82",
        "RmCdpMwS89hNYiVB6DkT0t0UmTj2",
        "HL6nOnZqdNSH4do33GIZWYwHmvZ2",
        "W3wkDbijTHMaxSunTEJ7rzoYDmE3",
        "S9lyIr9rUCfndbgmxy9O4rkEUnA2",
      ],
      items: [
        // { email: 'test1@analitica.id', sesi: "", penyisihan: "" },
        { email: 'bmec.unair@analitica.id', sesi: "", penyisihan: "" },
        { email: 'BMEC-000@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-001@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-002@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-003@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-004@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-005@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-006@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-007@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-008@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-009@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-010@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-011@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-012@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-013@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-014@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-015@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-016@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-017@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-018@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-019@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-020@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-021@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-022@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-023@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-024@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-025@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-026@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-027@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-028@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-029@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-030@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-031@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-032@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-033@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-034@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-035@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-036@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-037@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-038@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-039@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-040@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-041@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-042@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-043@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-044@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-045@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-046@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-047@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-048@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-049@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-050@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-051@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-052@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-053@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-054@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-055@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-056@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-057@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-058@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-059@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-060@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-061@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-062@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-063@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-064@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-065@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-066@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-067@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-068@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-069@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-070@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-071@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-072@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-073@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-074@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-075@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-076@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-077@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-078@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-079@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-080@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-081@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-082@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-083@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-084@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-085@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-086@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-087@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-088@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-089@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-090@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-091@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-092@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-093@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-094@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-095@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-096@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-097@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-098@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-099@bmecunair2021.com', sesi: "", penyisihan: "" },
        { email: 'BMEC-100@bmecunair2021.com', sesi: "", penyisihan: "" },
      ],
    }
  },
  methods: {
    ...mapMutations([
      'setPid',
      'setPpid',
    ]),
    ...mapActions([
      'bindBmec',
    ]),
  },
  watch : {
    'doc.problem':function(val) {
      this.article = val.article ?? {}
      this.has_article = val.has_article ?? false
      this.question = val.description ?? {}
      this.options = val.options ?? []
      this.difficulty_selection = val.difficulty ?? null
      this.subject_selection = val.subject ?? null
      this.chapter_selection = val.chapter ?? null
      this.section_selection = val.section ?? null
    },
    'doc.tempExplanation':function(val) {
      this.tempExplanation = val
    },
    'doc.problemSolution':function(val) {
      this.solution = val.optionCorrect.findIndex((x) => x==1 )
      this.solution_scores = val.optionCorrect.map(x=>x+1)
    },
    'has_article':function(val) {
      this.enableProblemArticle(val)
    },
    'doc.explanation':function() {
      this.selected_explanation = null
    },
    'selected_explanation':function(val) {
      if(this.explanation[val]){
        this.explanation_steps = this.explanation[val].steps
        this.explanation_steps_length = this.explanation_steps.length
      }
    },
    ppid:function(val) {
      this.ppid_input = val
      this.setPid(null)
    },
    pid:function(val) {
      this.pid_input = val
    },
    pid_input:function(val) {
      this.setPid(val)
      this.getProblem()
      this.selected_explanation = null
      this.explanation_steps = []
      this.explanation_title_input = ''
      this.explanation_steps_length = 0
    },
    solution:function(val) {
      let optionCorrect = [0,0,0,0]
      Array.from(Array(this.options.lenth)).forEach(()=>{
        optionCorrect.push(0)
      })
      optionCorrect[val] = 1
      this.setProblemSolution({
        optionCorrect: optionCorrect
      })
    },
    solution_scores:function(val) {
      let optionCorrect = val
      this.setProblemSolution({
        optionCorrect: optionCorrect.map(x=>x-1)
      })
    },
    difficulty_selection:function(val) {
      if(val!=null){
        this.setProblemDifficulty(val)
      }
    },
    subject_selection:function(val) {
      if(val!=null){
        this.setProblemSubject(val)
      }
    },
    chapter_selection:function(val) {
      if(val!=null){
        this.setProblemChapter(val)
      }
    },
    section_selection:function(val) {
      if(val!=null){
        this.setProblemSection(val)
      }
    }
  },
  created() {
    this.uids.forEach((uid, idx)=>{
      db.collection(`users/${uid}/sources/analitica/recordActivities/`).doc('#p2021.bmec.to').onSnapshot(doc=>{
        if(!doc.exists){
          this.items[idx].sesi = "x"
        }else{
          if(doc.data().plots["demo"]?.isFinished){
            this.items[idx].sesi = "demo"
          } else if(doc.data().plots["sesi 1"]?.isFinished){
            this.items[idx].sesi = "sesi 1"
          }else if(doc.data().plots["sesi 2"]?.isFinished){
            this.items[idx].sesi = "sesi 2"
          }
        }
      })
      db.collection(`users/${uid}/sources/analitica/recordExercises/`).doc('#p2021.bmec.to.to3').onSnapshot(doc=>{
        if(!doc.exists){
          this.items[idx].penyisihan = "belum dimulai"
        }else{
          let hid = doc.data().lastHid
          console.log(doc.data())
          console.log(doc.data().lastHid)
          if(!hid) this.items[idx].penyisihan = "belum dimulai"
          else db.collection(`/sources/analitica/histories/`).doc(hid).onSnapshot(doc=>{
            let history = doc.data()
            let sid = history.result?.sid
            if(!sid) this.items[idx].penyisihan = "belum dimulai"
            else if(history.result?.isFinished) this.items[idx].penyisihan = `selesai. skor: ${history.result.assesment.score} (${history.dateFinish.toDate().toLocaleString()})`
            else db.collection(`users/${uid}/sheets/`).doc(sid).onSnapshot(doc=>{
              let answered = 0
              let total = Object.values(doc.data().content).length
              Object.values(doc.data().content).forEach(x=>{
                if(x.answer>-1) answered++
              })
              this.items[idx].penyisihan = `berlangsung ${answered}/${total} (${history.dateCreated.toDate().toLocaleString()})`
            })
          })
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variables.scss";

*{
  text-align:left
}

.actions {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.export {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
  pre {
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.05);
    color: rgba($color-black, 0.8);
  }
  code {
    display: block;
    white-space: pre-wrap;
  }
}
.scroll {
    max-height: 100px;
    overflow-y: auto;
}
</style>